import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://cc041b205a79464bb402e8dca5bb799b@o524118.ingest.us.sentry.io/6061949",
  debug: false,
  environment: "production",
  release: "0.0.1",
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});